const chaindId = 1;

export const ethereumMainetInfos = {
    chaindId,
    chainName: 'Ethereum',
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://etherscan.io'],
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
};
